import {axiosInstance} from "../../Api/AxiosConfig";
import React, {useEffect, useState} from "react";
import {CarSearchBox} from "../../Components/Navigation/CarSearchBox";
import {InitControlValueData} from "../../Components/Navigation/CarFilterBar";
import {OrderLine} from "./OrderLine";
import {Waiting} from "../../Components/System/Waiting";
import {OrdersFilterLink} from "./OrdersFilterLink";
import {getUrlParameter} from "../../Hooks/UseGenericFunctions";

export const Orders = ({selected}) => {
    const [data, setData] = useState();
    const [filteredData, setFilteredData] = useState([]);
    const [filterBy, ] = useState('All');
    const [updateData, setUpdateData] = useState(false);
    const [selectedCarId, setSelectedCarId] = useState('Morten');
    const [filterData, setFilterData] = useState(InitControlValueData);
    const [ordersMissingPayment, setOrdersMissingPayment] = useState([]);
    const [ordersReadyForPickup, setOrdersReadyForPickup] = useState([]);
    const [ordersMissingTransport, setOrdersMissingTransport] = useState([]);
    const [, setOrdersArchived] = useState([]);
    const [, setOrdersUnderway] = useState([]);

    let filterView = getUrlParameter('filterView', '0');

    useEffect(() => {
        console.time('Data fetch');
        if (selected) {
            axiosInstance
                .get('/shop/orders')

                .then((response) => {
                    console.timeEnd('Data fetch');
                    setData(response.data.list);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [updateData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (data) {
            if (filterBy === 'All') {
                setFilteredData(data);
            } else {
                setFilteredData(data.filter(car => car.steps.some(step => step.step === filterBy && step.status === 'complete')));
            }
        }
    }, [filterBy, data]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (data) {
            if (filterView === '0') {
                setFilteredData(data);
            }

            if (filterView === '1') {
                setFilteredData(data.filter(order =>
                    order.steps.some(step => step.step === '02' && (step.status === '' || step.status === 'awaiting'))
                ));
            }

            if (filterView === '2') {
                setFilteredData(data.filter(order =>
                    order.steps.some(step => step.step === '04' && step.status === 'complete') &&
                    order.steps.some(step => step.step === '06' && step.status === '') &&
                    order.steps.some(step => step.step === '02' && step.status === 'complete')
                ));
            }

            if (filterView === '3') {
                setFilteredData(data.filter(order =>
                    order.transport_select.currentSelect.hash === '' &&
                    !order.transport_select.locked
                ));
            }

            setOrdersMissingPayment(data.filter(order =>
                order.steps.some(step => step.step === '02' && (step.status === '' || step.status === 'awaiting'))
            ));

            setOrdersReadyForPickup(data.filter(order =>
                order.steps.some(step => step.step === '04' && step.status === 'complete') &&
                order.steps.some(step => step.step === '06' && step.status === '') &&
                order.steps.some(step => step.step === '02' && step.status === 'complete')
            ));

            setOrdersMissingTransport(data.filter(order =>
                order.transport_select.currentSelect.hash === '' &&
                !order.transport_select.locked
            ));

            setOrdersUnderway(data.filter(order =>
                order.steps.some(step => step.step === '06' && step.status === 'complete') &&
                order.steps.some(step => step.step === '04' && step.status === 'complete') &&
                order.steps.some(step => step.step === '02' && step.status === 'complete')
            ));

            setOrdersArchived(data.filter(order =>
                order.car_is_archived === true
            ));
        }

    }, [data, filterView]) // eslint-disable-line react-hooks/exhaustive-deps

    // is the number even or odd?

    const filterList = (data) => {
        let dataToFilter = data.filter(order =>
            order.vin_no.toLowerCase().includes(filterData.search_text.toLowerCase()) || order.designation.toLowerCase().includes(filterData.search_text.toLowerCase()) || order.car_id.toString().includes(filterData.search_text.toLowerCase())
        );

        return dataToFilter;
    }

    if (data && data.length > 0) {
        return (
            <>
                <div className={`hidden screen-small:block`}>
                    <div className={`flex mr-3 items-center mt-4 max-w-[970px] px-10 mb-8`}>
                        <CarSearchBox fnSearchValueChange={(filterValues) => setFilterData(filterValues)} placeholder="Search - VIN Number, description"/>
                    </div>

                    <div className="flex items-center px-12">
                        <div className="mr-8 text-sm">Filter list:</div>

                        {ordersMissingPayment.length > 0 &&
                            <OrdersFilterLink filterViewNumber='1' title="Missing payment" count={ordersMissingPayment.length} parentClass="mr-8" countClass="bg-red-200 text-black"/>
                        }

                        {ordersReadyForPickup.length > 0 &&
                            <OrdersFilterLink filterViewNumber='2' title="Ready for pickup" count={ordersReadyForPickup.length} parentClass="mr-8" countClass="bg-primary-color text-white"/>
                        }

                        {ordersMissingTransport.length > 0 &&
                            <OrdersFilterLink filterViewNumber='3' title="Missing transport selection" count={ordersMissingTransport.length} parentClass="mr-8" countClass="bg-red-200 text-black"/>
                        }

                        <OrdersFilterLink filterViewNumber='0' title="Show all" count={data.length} countClass="bg-primary-color text-white"/>
                    </div>


                    <div className="min-w-[1800px] px-10 mt-8">
                        <div className="sticky top-0 z-10">
                            <div className="flex items-center mb-2 font-semibold">
                                <div className="rounded bg-white px-4 py-1 flex items-center shadow h-[90px] mr-4">
                                    <div className="w-[50px] mr-2">
                                    </div>
                                    <div className="w-[80px]">
                                        Car&nbsp;#
                                    </div>
                                    <div className="w-[200px]">
                                        VIN Number
                                    </div>
                                    <div className="w-[300px] overflow-hidden mr-6">
                                        Description
                                    </div>
                                    <div className="w-[100px] mr-2">
                                        Reg. date
                                    </div>
                                    <div className="w-[100px]">
                                        Order date
                                    </div>
                                    <div className="w-[70px] text-right pr-4">
                                        Km
                                    </div>
                                </div>
                                {data[0].steps.map((step) => (
                                    <div key={'step_header_' + step.name} className={`w-[120px] flex items-center justify-center bg-white shadow p-4 mx-1 pl-5 rounded h-[90px] ${filterView === '3' ? 'hidden' : ''}`}>
                                        <div className="text-center">{step.name}</div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {filterList(filteredData).map((car, index) => {
                            return (
                                <div key={'order_line_' + car.hash}>
                                    <OrderLine orderLine={car} rowNumber={index} setSelectedCarId={setSelectedCarId} selectedCarId={selectedCarId} showPickupOptions={filterView === '3'} fnUpdateData={() => setUpdateData(!updateData)}/>
                                </div>
                            )

                        })}

                    </div>

                    <div className="h-[500px]"></div>

                </div>

                <div className={`screen-small:hidden flex mt-[250px] justify-center w-screen h-screen`}>
                    <div className="font-bold text-gray-500 p-8 text-center">Not available on smartphone. <br/>Please use desktop computer or larger screen</div>
                </div>
            </>
        )
    }

    if (!data) {
        return (
            <Waiting title="Loading orders ..."/>
        )
    }

    if (data && data.length === 0) {
        return (
            <div className={`w-full h-screen flex items-center justify-center font-medium text-3xl pb-16`}>
                No orders found
            </div>
        )
    }
}