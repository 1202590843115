import {PresalesListTableRow} from "./PresalesListTableRow";
import {SortableHeaderText} from "../../Components/Table/SortableHeaderText";
import {DivCell} from "./DivCell";

export const PresalesListTable = ({cars, activeCarId, activeSortField, activeSortAscending, fnSetSortByField, fnSetSortByFieldOrder, fnUpdateCarList, fnSetActiveCar}) => {
    return (
        <div>
            <div className="sticky top-0 z-10">
                <div className="text-sm flex items-center border-l-[5px] border-l-white bg-white px-2 py-4 mb-2">
                    <DivCell className="w-[45px]"><SortableHeaderText title="Like" activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="justify-center" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="like" sortAscendingDefault={false}/></DivCell>
                    <DivCell className="w-[50px]"><SortableHeaderText title="" activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="justify-center" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="designation" sortAscendingDefault={false}/></DivCell>
                    <DivCell className="w-[45px] 2xl:w-[45px]"><SortableHeaderText title="#" activeSortAscending={activeSortAscending} activeSortField={activeSortField} setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="car_id"/></DivCell>
                    <DivCell className="w-[120px] 2xl:w-[250px]"><SortableHeaderText title="Description" activeSortAscending={activeSortAscending} activeSortField={activeSortField} setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="designation"/></DivCell>
                    <DivCell className="w-[130px] hidden 2xl:block"><SortableHeaderText title="Type" activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="pl-[30px]" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="buy_status"/></DivCell>
                    <DivCell className="w-[100px]"><SortableHeaderText title="First Reg." activeSortAscending={activeSortAscending} activeSortField={activeSortField} textAlignment="text-center" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="reg_date"/></DivCell>
                    <DivCell className="w-[60px]"><SortableHeaderText title="Km" activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="justify-center" textAlignment="text-center" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="km"/></DivCell>
                    <DivCell className="w-[80px] hidden xl:block"><SortableHeaderText title="Damages" activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="justify-end" textAlignment="text-center" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="damage_amount" sortAscendingDefault={false}/></DivCell>
                    <DivCell className="w-[160px]"><SortableHeaderText title="Price" activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="justify-center" textAlignment="text-center" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="price"/></DivCell>
                    <DivCell className="w-[75px]"><SortableHeaderText title="Buy" activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="justify-center" textAlignment="text-center" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="offer_amount" sortAscendingDefault={false}/></DivCell>
                    <DivCell className="w-[120px]"><SortableHeaderText title="Offer" activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="justify-center" textAlignment="text-center" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="offer_amount" sortAscendingDefault={false}/></DivCell>
                    <DivCell className="w-[55px] hidden screen-large:block"><SortableHeaderText title="HP" activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="justify-center" textAlignment="text-center" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="horsepower"/></DivCell>
                    <DivCell className="w-[160px] hidden screen-large:block"><SortableHeaderText title="Color" activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="justify-start" textAlignment="text-left" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="color"/></DivCell>
                    <DivCell className="w-[160px] hidden screen-large:block"><SortableHeaderText title="Fuel" activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="justify-left" textAlignment="text-center" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="fuel_name" sortAscendingDefault={false}/></DivCell>
                    <DivCell className="w-[160px] hidden 4xl:block"><SortableHeaderText title="Gear" activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="justify-center" textAlignment="text-center" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="gear_name" sortAscendingDefault={false}/></DivCell>
                    <DivCell className="w-[160px] hidden 4xl:block"><SortableHeaderText title="Body type" activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="justify-center" textAlignment="text-center" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="body_name" sortAscendingDefault={false}/></DivCell>
                </div>
            </div>

            <div>
                {cars.map((car) => (
                    <PresalesListTableRow key={car.car_id} car={car} activeCarId={activeCarId} fnUpdateCarList={fnUpdateCarList} fnSetActiveCar={fnSetActiveCar}/>
                ))
                }
            </div>
        </div>
    )
}