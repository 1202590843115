import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Home } from './Pages/Home';
import { NotFound404 } from "./Pages/Messages/NotFound404";
import { Login } from "./Pages/Security/Login";
import './App.css';
import './input.css';
import { TailwindUi } from "./Pages/Experiments/TailwindUi";
import { OrderReceived } from "./Pages/Messages/OrderReceived";
import { DailyOverview } from "./Pages/Management/Dashboards/DailyOverview";
import { MantineProvider } from "@mantine/core";
import { getPusherCluster } from "./Hooks/UseBaseData";
import { ChangePassword } from "./Pages/Security/ChangePassword";
import { ForgottenPassword } from "./Pages/Security/ForgottenPassword";
import { GlobalDataProvider } from "./GlobalState/GlobalDataProvider";
import { Logout } from "./Pages/Security/Logout";
import { Dashboard } from "./Pages/Dashboard/Dashboard";
import {UnderMaintenance} from "./Components/System/Messages/UnderMaintenance";
import {PerformanceMonitor} from "./Pages/System/PerformanceMonitor";

function App() {
    const [defaultPath, setDefaultPath] = useState(null);

    useEffect(() => {
        // Detect if the device is mobile and set the default path accordingly
        const path = window.matchMedia("(max-width: 768px)").matches ? "/dashboard/20" : "/dashboard/30";
        setDefaultPath(path);
    }, []);

    let goToLogin = (getPusherCluster() === null && window.location.pathname !== '/login' && window.location.pathname !== '/forgottenpassword' && window.location.pathname !== '/changepassword');

    useEffect(() => {
        if (goToLogin) {
            window.location = '/login';
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (defaultPath === null) {
        return <div>Loading...</div>;
    }

    return (
        <GlobalDataProvider>
            <MantineProvider theme={{ colorScheme: 'light' }}>
                <div className="App h-screen w-screen">
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Navigate to={defaultPath} replace />} />
                            <Route path="/dashboard/:menuItem?/:filterView?" element={<Dashboard />} />
                            <Route path="/orderreceived" element={<OrderReceived />} />
                            <Route path="/experiment" element={<TailwindUi />} />
                            <Route path="/checkout" element={<Home menuItemSelected={2} />} />
                            <Route path="/orders" exact element={<Home menuItemSelected={3} />} />
                            <Route path="/vendororders" element={<Home menuItemSelected={4} />} />
                            <Route path="/terms" element={<Home menuItemSelected={5} />} />
                            <Route path="/ordersnew" element={<Home menuItemSelected={6} />} />
                            <Route path="/logout" element={<Logout />} />
                            <Route path="/managementdashboard" element={<DailyOverview />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/login" element={<UnderMaintenance/>}/>
                            <Route path="/" exact element={<UnderMaintenance />}/>
                            <Route path="/changepassword" element={<ChangePassword />} />
                            <Route path="/forgottenpassword" element={<ForgottenPassword />} />
                            <Route path="/performancemonitor" element={<PerformanceMonitor />} />
                            {/*<Route path="*" element={<Navigate to="/login" replace />} />*/}
                            <Route path="*" element={<NotFound404 />} />
                        </Routes>
                    </BrowserRouter>
                </div>
            </MantineProvider>
        </GlobalDataProvider>
    );
}

export default App;
