import {CarStatusPill} from "../Cars/CarStatusPill";
import {Tooltip} from "@mantine/core";
import {CalendarDaysIcon} from "@heroicons/react/24/outline";
import React from "react";

export const PurchaseTypePill = React.memo(({car}) => {
    return (
        <>
            {car.buy_status === 'confirmed' && !car.is_sold &&
                <div>
                    <div className="flex items-center">
                        <CarStatusPill title='Confirmed' explanation="Carzelle has purchased the car so we can guarantee that it is available for your purchase" bgColor='bg-confirmed' className='text-xs w-[100px]'/>
                        {car.in_stock_expected < 3 &&
                            <Tooltip label={"Expected within " + car.in_stock_expected + " week(s)"} openDelay={1500}>
                                <div className="ml-2"><CalendarDaysIcon className="h-5 w-5"/></div>
                            </Tooltip>
                        }
                    </div>
                </div>
            }
            {car.buy_status === 'fast_delivery' && !car.is_sold &&
                <div>
                    <CarStatusPill title='Fast Delivery' explanation="Car is in stock in one of our locations and can be available for delivery immediately" bgColor='bg-fast-delivery' foreColor="text-white" className='text-xs w-[100px]'/>
                </div>
            }

            {car.buy_status === 'listing' && !car.is_sold &&
                <div>
                    <CarStatusPill title='Partner Listing' explanation="Reserve this car now, and we'll promptly confirm its availability with you." bgColor='bg-presales' foreColor="text-gray-700" className='text-xs w-[100px]'/>
                </div>
            }
        </>
    )
});