import React, {useEffect, useState} from "react";
import {axiosInstance} from "../../Api/AxiosConfig";
import {PresalesListTable} from "./PresalesListTable";
import {Waiting} from "../../Components/System/Waiting";
import {CarFilter, InitControlValueData} from "./CarFilter";
import {CarDetailsView} from "./CarDetailsView";
import {useGlobalDataContext} from "../../GlobalState/GlobalDataProvider";
import {registerUserActivity} from "../../Api/StatService";
import {StatusAndCheckoutButton} from "./StatusAndCheckoutButton";
import {useLocation} from "react-router-dom";

export const Presales = ({fnUpdateGlobalData, pushUpdate}) => {
    const [data, setData] = useState();
    const [makes, setMakes] = useState();
    const [fuels, setFuels] = useState();
    const [bodies, setBodies] = useState();
    const [gears, setGears] = useState();
    const [sortByField, setSortByField] = useState('designation');
    const [sortByFieldAscending, setSortByFieldAscending] = useState(true);
    const [filterData, setFilterData] = useState(InitControlValueData);
    const [updateCarList, setUpdateCarList] = useState(false);
    const [activeCar, setActiveCar] = useState();
    const [showDetails, setShowDetails] = useState(false);
    const {globalData} = useGlobalDataContext();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const showBasket = searchParams.get('showBasket');

    const isShowBasket = showBasket === 'true';

    useEffect(() => {
        console.log("Getting data" + new Date().toISOString());
        axiosInstance
            .get('/shop/buy-list', {
                params: {
                    listTypeId: 0
                }
            })

            .then((response) => {
                fnUpdateGlobalData();
                setMakes(response.data.makes);
                setData(response.data.list);
                setFuels(response.data.fuel);
                setBodies(response.data.body);
                setGears(response.data.gear);
                registerUserActivity(3, 'Fetched Presales list');
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [updateCarList, pushUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setShowDetails(true);
    }, [activeCar]);

    const findMinMaxPrices = (cars) => {
        if (cars.length === 0) {
            return {minPrice: 0, maxPrice: 0}; // Return default values for empty array
        }

        const {minPrice, maxPrice} = cars.reduce(
            (acc, car) => {
                return {
                    minPrice: Math.min(acc.minPrice, car.price),
                    maxPrice: Math.max(acc.maxPrice, car.price)
                };
            },
            {minPrice: Infinity, maxPrice: -Infinity}
        );

        return {minPrice, maxPrice};
    };

    const findMinMaxKm = (cars) => {
        if (cars.length === 0) {
            return {minKm: 0, maxKm: 0}; // Return default values for empty array
        }

        const {minKm, maxKm} = cars.reduce(
            (acc, car) => {
                return {
                    minKm: Math.min(acc.minKm, car.km),
                    maxKm: Math.max(acc.maxKm, car.km)
                };
            },
            {minKm: Infinity, maxKm: -Infinity}
        );

        return {minKm, maxKm};
    };

    const findMinMaxDamageAmount = (cars) => {
        if (cars.length === 0) {
            return {minDamageAmount: 0, maxDamageAmount: 0}; // Return default values for empty array
        }

        const {minDamageAmount, maxDamageAmount} = cars.reduce(
            (acc, car) => {
                return {
                    minDamageAmount: Math.min(acc.minDamageAmount, car.damage_amount),
                    maxDamageAmount: Math.max(acc.maxDamageAmount, car.damage_amount)
                };
            },
            {minDamageAmount: Infinity, maxDamageAmount: -Infinity}
        );

        return {minDamageAmount, maxDamageAmount};
    };

    const filterAndSortList = (data) => {
        let dataToFilter = data;

        if (filterData.make_ids.length > 0) {
            dataToFilter = data.filter((car) => filterData.make_ids.includes(car.make_id));
        }

        if (filterData.fuel_ids.length > 0) {
            dataToFilter = data.filter((car) => filterData.fuel_ids.includes(car.fuel_id));
        }

        if (filterData.gear_ids.length > 0) {
            dataToFilter = data.filter((car) => filterData.gear_ids.includes(car.gear_id));
        }

        if (filterData.max_price > 0 || filterData.min_price > 0) {
            let minPrice = filterData.min_price > 0 ? filterData.min_price * 1000 : 0;
            let maxPrice = filterData.max_price > 0 ? filterData.max_price * 1000 : 999999999;
            dataToFilter = dataToFilter.filter((car) => car.price >= minPrice && car.price <= maxPrice);
        }

        if (filterData.max_km > 0 || filterData.min_km > 0) {
            let minKm = filterData.min_km > 0 ? filterData.min_km * 1000 : 0;
            let maxKm = filterData.max_km > 0 ? filterData.max_km * 1000 : 999999999;
            dataToFilter = dataToFilter.filter((car) => car.km >= minKm && car.km <= maxKm);
        }

        if (filterData.max_damage_amount > 0 || filterData.min_damage_amount > 0) {
            let minDamageAmount = filterData.min_damage_amount > 0 ? filterData.min_damage_amount * 100 : 0;
            let maxDamageAmount = filterData.max_damage_amount > 0 ? filterData.max_damage_amount * 100 : 999999999;
            dataToFilter = dataToFilter.filter((car) => car.damage_amount >= minDamageAmount && car.damage_amount <= maxDamageAmount);
        }

        if (filterData.search_text !== '') {
            dataToFilter = dataToFilter.filter((car) => (car.designation + car.vin_no + car.car_id + car.info_line_1 + car.info_line_2).toLowerCase().includes(filterData.search_text.toLowerCase()));
        }

        if (filterData.liked === true) {
            dataToFilter = dataToFilter.filter((car) => car.like !== null);
        }

        if (filterData.in_basket === true) {
            dataToFilter = dataToFilter.filter((car) => car.offer_amount > 0 || car.in_basket);
        }

        if (filterData.open_offers === true) {
            dataToFilter = dataToFilter.filter((car) => car.offer_amount_confirmed > 0);
        }

        if (filterData.fast_delivery === true) {
            dataToFilter = dataToFilter.filter((car) => car.buy_status === 'fast_delivery');
        }

        // eslint-disable-next-line array-callback-return
        dataToFilter = dataToFilter.sort((a, b) => {
            const aValue = a[sortByField];
            const bValue = b[sortByField];

            // Handle null or undefined values
            if (aValue == null && bValue == null) {
                return 0;
            }
            if (aValue == null) {
                return sortByFieldAscending ? -1 : 1;
            }
            if (bValue == null) {
                return sortByFieldAscending ? 1 : -1;
            }

            // Attempt to convert values to numbers
            const aValueNumber = Number(aValue);
            const bValueNumber = Number(bValue);

            // Check if both values are numbers
            if (!isNaN(aValueNumber) && !isNaN(bValueNumber)) {
                if (aValueNumber < bValueNumber) {
                    return sortByFieldAscending ? -1 : 1;
                }
                if (aValueNumber > bValueNumber) {
                    return sortByFieldAscending ? 1 : -1;
                }
                return 0;
            } else {

                const aDate = new Date(aValue);
                const bDate = new Date(bValue);
                if (!isNaN(aDate.getTime()) && !isNaN(bDate.getTime())) {
                    // Both values are dates
                    if (aDate < bDate) {
                        return sortByFieldAscending ? -1 : 1;
                    }
                    if (aDate > bDate) {
                        return sortByFieldAscending ? 1 : -1;
                    }
                    return 0; // if equal
                } else {
                    // At least one value is not a date, use string comparison
                    const aValueStr = String(aValue);
                    const bValueStr = String(bValue);
                    if (aValueStr < bValueStr) {
                        return sortByFieldAscending ? -1 : 1;
                    }
                    if (aValueStr > bValueStr) {
                        return sortByFieldAscending ? 1 : -1;
                    }
                    return 0; // if equal
                }
            }
        });

        return dataToFilter;
    }

    const animateBasketIcon = () => {
        const element = document.getElementById('shopping_bag');  // Get the div element

        element.classList.add('animate-bounce');
        element.classList.remove('bg-purchase-color')
        element.classList.add('bg-sky-500');

        setTimeout(() => {
            element.classList.remove('bg-sky-500');
            element.classList.remove('animate-bounce');
            element.classList.add('bg-purchase-color');
        }, 1650);
    }

    if (data && globalData) {

        const {minPrice, maxPrice} = findMinMaxPrices(data);
        const {minKm, maxKm} = findMinMaxKm(data);
        const {minDamageAmount, maxDamageAmount} = findMinMaxDamageAmount(data);

        return (
            <>
                {/*<LoadingOverlay visible={fetchingData} zIndex={20} overlayProps={{radius: "sm", blur: 2}}/>*/}
                <div className="lg:hidden flex items-center justify-center mt-12 p-8 text-2xl">
                    <div className="text-center">The presales function can only be used on desktop computers</div>
                </div>

                {/*<ScreenSizeIndicator/>*/}

                <div className="hidden lg:block">
                    <div className="mx-4 flex" style={{height: '92vh'}}>
                        <div className="relative mr-1 2xl:mr-4 h-[92vh] w-[100vw]">
                            <div className="absolute top-0 right-[-35px] z-20">
                                <StatusAndCheckoutButton cars={globalData.basket.basket_lines} sum={globalData.basket.basket_totals.amount} currency={globalData.basket.basket_totals.currency} showBasket={isShowBasket} fnUpdate={() => setUpdateCarList(!updateCarList)}/>
                            </div>
                            <div className={`overflow-auto ${(activeCar == null || activeCar === undefined || !showDetails) ? 'h-full' : 'h-[calc(92vh-265px)]'}`}>
                                <PresalesListTable cars={filterAndSortList(data)} activeCarId={activeCar === null || activeCar === undefined ? '' : activeCar.hash} activeSortField={sortByField} activeSortAscending={sortByFieldAscending} fnSetSortByField={setSortByField} fnSetSortByFieldOrder={setSortByFieldAscending} fnUpdateCarList={() => {
                                    setUpdateCarList(!updateCarList);
                                    animateBasketIcon();
                                }} fnSetActiveCar={setActiveCar}/>
                            </div>
                            {(activeCar !== null && activeCar !== undefined && showDetails) &&
                                <div className="w-full bg-white mt-2 rounded-lg p-4 h-[255px] min-h-[255px] max-h-[255px]">
                                    <CarDetailsView car={activeCar} height="h-[220px]" closeDetails={() => setShowDetails(false)}/>
                                </div>
                            }
                        </div>
                        <div className="min-h-[94vh]">
                            <div className="pb-4 h-full min-w-[170px]">
                                <CarFilter makes={makes} fuels={fuels} bodies={bodies} gears={gears} filterData={filterData} fnFilterChange={(filterValues) => setFilterData(filterValues)} minPrice={minPrice} maxPrice={maxPrice} minKm={minKm} maxKm={maxKm} minDamageAmount={minDamageAmount} maxDamageAmount={maxDamageAmount}/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <Waiting/>
        );
    }
}