import {formatNumberWithSeparator} from "../../Hooks/UseGenericFunctions";
import {VATIcon} from "../../Components/Cars/VATIcon";
import React, {useEffect, useState} from "react";
import {CarAttribute} from "../../Components/Cars/CarAttribute";
import {axiosInstance} from "../../Api/AxiosConfig";
import {CarImageModal} from "../../Components/Cars/CarImageModal";
import {CarNavigationThumbs} from "../../Components/Cars/CarNavigationThumbs";
import {XMarkIcon} from "@heroicons/react/24/solid";
import {SuggestedPriceCalculation} from "../../Components/Cars/SuggestedPriceCalculation";

export const CarDetailsView = ({car, closeDetails, height}) => {
    const [showImageIndex, setShowImageIndex] = useState(0);
    const [data, setData] = useState();
    const [showImageModal, setShowImageModal] = useState(false);

    useEffect(() => {
        axiosInstance
            .get(car.info_endpoint)

            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [car]);
    const handleThumbnailClick = (index) => {
        setShowImageIndex(index);
        setShowImageModal(true);
    }

    if (data) {
        return (
            <div className={`relative`}>
                <div onClick={closeDetails} className="absolute right-[-5px] top-[-8px] bg-white rounded-full p-1 grow-on-hover-5 cursor-pointer hover:bg-gray-200">
                    <XMarkIcon className="grow-on-hover-15 w-7 h-7"/>
                </div>
                <div className={`flex items-start ${height}`}>
                    <div className="cursor-pointer" onClick={() => handleThumbnailClick(0)}>
                        <img className='rounded-full w-[80px] h-[80px] min-w-[80px] min-h-[80px] bg-white object-scale-down shadow' src={car.list_image} alt={'image for ' + car.designation}/>
                    </div>
                    <div className="flex items-start w-full h-[300px]">
                        <div className="relative px-4 w-[400px] mr-6">
                            <div className="font-bold text-xl">{car.designation}</div>
                            <div className="flex items-center font-bold text-purchase-color">
                                <div className="text-2xl">{formatNumberWithSeparator(parseInt(car.price), '.')}&nbsp;{car.currency}</div>
                                <div className='flex items-center ml-2'><VATIcon vatStatusId={car.vat_status_id}/></div>
                            </div>
                            <div className="absolute top-[118px] mt-4">
                                <CarNavigationThumbs images={data.images.all} maxImagesNumber={5} onClick={handleThumbnailClick}/>
                            </div>
                        </div>
                        <div className={`${height} overflow-y-auto`}>
                            <div className="xl:flex text-sm flex-wrap justify-start">
                                <div className='flex-1 min-w-[250px] mr-4 mb-4'>
                                    <CarAttribute label='Car #' value={car.car_id}/>
                                    <CarAttribute label='VIN' value={data.cars.vin_no.value}/>
                                    <CarAttribute label='Make' value={data.cars.make_name.value}/>
                                    <CarAttribute label='Model' value={data.cars.model.value}/>
                                    <CarAttribute label='Damage' value={data.cars.damage_amount.value}/>
                                </div>
                                <div className='flex-1 min-w-[250px] mr-4 mb-4'>
                                    <CarAttribute label='Model year' value={data.cars.model_year.value}/>
                                    <CarAttribute label='First reg.' value={data.cars.reg_date.value}/>
                                    <CarAttribute label='KM' value={data.cars.km.value}/>
                                    <CarAttribute label='Color' value={data.cars.color.value === null ? data.cars.factory_color.value : data.cars.color.value}/>
                                    <CarAttribute label='Service' value={data.cars.service.value}/>
                                </div>
                                <div className='flex-1 min-w-[250px] mr-4 mb-4'>
                                    <CarAttribute label='Car type' value={data.cars.car_type_name.value}/>
                                    <CarAttribute label='Body type' value={data.cars.body_type_name.value}/>
                                    <CarAttribute label='Seats' value={data.cars.seats.value}/>
                                    <CarAttribute label='Fuel' value={data.cars.fuel_name.value}/>
                                </div>
                                <div className='flex-1 min-w-[250px] mr-4 mb-4'>
                                    <CarAttribute label='CO2 rating' value={data.cars.co2_rating_wltp.value}/>
                                    <CarAttribute label='Gear' value={data.cars.gear_name.value}/>
                                    <CarAttribute label='Battery size' value={data.cars.battery_size.value} hideWhenEmpty={true}/>
                                    {data.cars.fuel_name.value !== 'Electric' && <CarAttribute label='Engine size' value={data.cars.engine_size.value}/>}
                                    <CarAttribute label='HP' value={data.cars.horsepower.value}/>
                                </div>
                                <div className='flex-1 min-w-[250px] mr-4 mb-4'>
                                    {data.cars.condition.value !== null &&
                                        <div>
                                            <div className='text-base font-semibold mb-3'>Condition</div>
                                            <div className='max-w-[750px] whitespace-normal'>
                                                {data.cars.condition.value}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {data.equipment.length > 0 &&
                                <div className="w-full flex flex-wrap max-w-full">
                                    <div className='whitespace-normal'>
                                        {data.equipment.map((item) => (
                                            <span key={item.name} className='mb-[10px] whitespace-nowrap mr-2 px-2 py-1 rounded bg-gray-200 inline-block text-sm' style={{lineHeight: '15px'}}>{item.name}</span>
                                        ))}
                                    </div>
                                </div>
                            }
                            <div className="mb-4">
                                {data.dkPrice.status &&
                                    <SuggestedPriceCalculation car={car} data={data}/>
                                }
                            </div>

                        </div>
                    </div>
                </div>

                <div className='flex flex-wrap items-center my-4'>
                    <CarImageModal images={data.images.all} visible={showImageModal} setVisible={setShowImageModal} imageIndex={showImageIndex}/>
                </div>
            </div>
        )
    }
}