import React from "react";
import {axiosInstance} from "../../Api/AxiosConfig";
import {ShoppingBagIcon} from "@heroicons/react/24/outline";
import {CheckIcon} from "@heroicons/react/24/solid";
import {Tooltip} from "@mantine/core";

export const AddToBasketButton = React.memo(({hash, carInBasket, fnUpdate, text, tooltip, className, bgColor = 'bg-purchase-color'}) => {
    const addItemToBasket = (hash) => {
        axiosInstance
            .post("shop/add-to-basket", {carHash: hash})

            .then(() => {
                fnUpdate();
            })
            .catch((error) => {
                console.error("Error adding car to basket:", error);
            });
    }

    const deleteItem = (hash) => {
        axiosInstance
            .delete("shop/remove-from-basket/" + hash)

            .then(() => {
                fnUpdate();
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }

    return (
        <Tooltip label={tooltip} multiline w={200} openDelay={1500}>
            <div>
                <button
                    className={`relative rounded-lg ${!text ? 'px-6' : 'px-2'} h-[30px] flex items-center justify-center hover:scale-[1.15] duration-300 ` + className + ' ' + (carInBasket !== null ? ' bg-un-purchase-color' : ' ' + bgColor)}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (carInBasket !== null) {
                            deleteItem(hash);
                            return;
                        } else {
                            addItemToBasket(hash);
                        }
                    }}
                >
                    {!text && carInBasket &&
                        <div className="absolute top-3 right-3"><CheckIcon className={`w-3 h-3`}/></div>
                    }
                    {text && carInBasket &&
                        <div className="absolute top-0 right-0"><CheckIcon strokeWidth={2} className={`w-3 h-3`}/></div>
                    }
                    {!text &&
                        <span className={"flex material-symbols-outlined text-[20px] " + (carInBasket !== null ? ' text-gray-400' : 'text-white ')} title={tooltip}>
                            <ShoppingBagIcon className="w-5 h-5"/>
                        </span>
                    }
                    {text &&
                        <span className={`text-xs whitespace-nowrap ${carInBasket !== null ? 'text-gray-400' : 'text-white'}`}>
                            {text}
                        </span>
                    }
                </button>
            </div>
        </Tooltip>
    )
});
