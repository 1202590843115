import {CarzelleInvoiceData} from "./CarzelleInvoiceData";
import {DownloadButton} from "./DownloadButton";

export const MyCarzelleInvoiceList = ({cars}) => {
    const formatAmount = (amount) => {
        return new Intl.NumberFormat('de-DE').format(amount);
    };

    return (
        <>
            <div className="flex items-center border-b border-gray-200 py-2">
                <div className="whitespace-nowrap text-sm font-bold min-w-[60px]">

                </div>
                <div className="whitespace-nowrap text-sm font-bold min-w-[80px]">
                    Inv. no.
                </div>
                <div className="whitespace-nowrap text-sm font-bold min-w-[190px]">
                    VIN
                </div>
                <div className="whitespace-nowrap text-sm font-bold min-w-[290px]">
                    Car
                </div>
                <div className="whitespace-nowrap text-sm font-bold min-w-[90px]">
                    Date
                </div>
                <div className="whitespace-nowrap text-sm font-bold min-w-[90px]">
                    Amount
                </div>
                <div className="whitespace-nowrap text-sm font-bold min-w-[140px] flex justify-center">
                    <DownloadButton title="Download all" openInTab={false} url={"/documents/download-zip/due-invoices"}/>
                </div>
                <div className="whitespace-nowrap text-sm font-bold min-w-[140px] flex justify-center">

                </div>
            </div>
            {cars.map((car) => (
                <div key={"invoice_list_" + car.vin_no} className="flex items-center border-b border-gray-200 py-2">
                    <div className="flex items-center text-gray-500">
                        <div className="text-sm w-[60px]">
                            <img src={car.list_image} className="rounded-full w-10 h-10 object-cover"/>
                        </div>
                        <div className="text-sm w-[80px]">
                            {car.no}
                        </div>
                        <div className="text-sm w-[190px]">
                            {car.vin_no}
                        </div>
                        <div className="whitespace-nowrap text-sm pr-2 overflow-hidden min-w-[290px]">
                            {car.designation}
                        </div>
                        <div className="text-sm w-[90px]">
                            {car.date}
                        </div>
                        <div className="text-sm w-[90px] mr-3">
                            {formatAmount(car.amount)}&nbsp;DKK
                        </div>
                        <div className="text-sm w-[140px] flex justify-center mr-2">
                            {car.download_link && <DownloadButton title="Download invoice" fileName={'Invoice ' + car.no} url={car.download_link} openInTab={false}/>}
                        </div>
                        <div className="text-sm w-[140px] flex">
                            <DownloadButton title="Show invoice" url={car.link}/>
                        </div>
                    </div>
                </div>
            ))
            }
        </>
    )
}