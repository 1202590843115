import {useEffect, useState} from "react";
import {BellIcon, FingerPrintIcon, HomeIcon, ScaleIcon, UserIcon, UsersIcon, PowerIcon, ClockIcon, MapPinIcon, CpuChipIcon, BuildingStorefrontIcon, CheckIcon} from "@heroicons/react/24/outline";
import {MyCarzelleInvoiceList} from "./MyCarzelleInvoiceList";
import {MyCarzelleSectionHeader} from "./MyCarzelleSectionHeader";
import {CarzelleInvoiceData} from "./CarzelleInvoiceData";
import {MyCarzelleLatestPurchaseList} from "./MyCarzelleLastestPurchaseList";
import {ChevronRightIcon} from "@heroicons/react/24/solid";
import {axiosInstance} from "../../Api/AxiosConfig";
import {MyCarzelleOpenOffers, MyOpenOffers} from "./MyOpenOffers";

const MenuItem = ({id, choosenId, Icon, className, title, onClick, showDivider = false}) => {
    let chosenClassName = id === choosenId ? "bg-blue-100" : "";
    return (
        <div onClick={() => onClick(id)} className={`${className} ${chosenClassName} rounded-2xl cursor-pointer transition-transform duration-200 transform hover:shadow-sm hover:scale-[1.05] py-2 px-4 hover:bg-blue-50`}>
            <div className="flex items-center">
                <div className="mr-2"><Icon className="w-5 h-5 text-gray-500"/></div>
                <div>{title}</div>
            </div>
        </div>
    )
}

const StatElement = ({title, value, className}) => {
    return (
        <div className={`rounded-2xl flex items-center ${className}`}>
            <div className={`pl-4 `}>
                <div className="text-3xl font-extrabold">{value}</div>
            </div>
            <div className="text-sm ml-4 pr-4">{title}</div>
        </div>
    )
}

const ShortcutButton = ({title, Icon, onClick}) => {
    return (
        <div onClick={onClick} className="flex items-center max-w-max pr-4 text-sm cursor-pointer hover:scale-[1.1] transition duration-150 ease-in-out bg-gray-100 p-2 rounded-xl">
            <div className="mr-2"><Icon className="w-5 h-5 text-gray-500"/></div>
            <div className="underline">{title}</div>
            <div className="ml-2"><ChevronRightIcon className="w-5 h-5 text-gray-300"/></div>
        </div>
    )
}

export const MyCarzelle = ({selected}) => {
    const [chosenMenuItemId, setChosenMenuItemId] = useState(1);
    const [updateData, setUpdateData] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        if (selected) {
            axiosInstance
                .get('/shop/my-site')

                .then((response) => {
                    setData(response.data);
                    console.log(response.data);

                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [updateData]); // eslint-disable-line react-hooks/exhaustive-deps

    if (data) {
        return (
            <div className="font-normal overflow-y-scroll h-screen">
                <div className="flex bg-white rounded-xl mb-16">
                    <div className="flex flex-col flex-grow p-6">
                        {chosenMenuItemId === 1 && <div>
                            <div className="text-2xl font-bold mb-8">My Carzelle</div>

                            <div className="flex items-start mb-8">
                                {data.open_offers.length > 0 && <div className="w-[200px] mr-4"><StatElement title="Open Offers" value={data.open_offers.length} className="bg-blue-400 text-white h-16"/></div>}
                                {data.unpaid_cars.length > 0 && <div className="w-[200px] mr-4"><StatElement title="Invoices overdue" value={data.unpaid_cars.length} className="bg-red-400 text-white h-16"/></div>}
                            </div>


                            <div className="ml-4 inline-block">
                                <div>
                                    <MyCarzelleSectionHeader title="Open offers" number={data.open_offers.length}/>
                                </div>
                                {data.open_offers.length > 0 &&
                                    <MyCarzelleOpenOffers cars={data.open_offers}/>
                                }
                                {data.open_offers.length === 0 &&
                                    <div className="text-base text-gray-600 mb-[50px]">Here you can see your open offers, but you don't have any at the moment.</div>
                                }

                                <div><MyCarzelleSectionHeader title="Invoices Overdue" number={data.unpaid_cars.length}/></div>
                                {data.unpaid_cars.length > 0 &&
                                    <>
                                        <MyCarzelleInvoiceList cars={data.unpaid_cars}/>
                                        <div className="underline text-sm mt-4 text-gray-400"><a href="/dashboard/50">View all invoices ...</a></div>
                                    </>
                                }
                                {data.unpaid_cars.length === 0 &&
                                    <div className="flex items-center">
                                        <div className="text-base text-gray-600 mr-2 mb-4">Here you can see your unpaid invoices, but you don't have any at the moment</div>
                                    </div>
                                }

                                <div>
                                    <MyCarzelleSectionHeader title="Latest 5 purchases" number={data.last_purchases.length}/>
                                </div>

                                <MyCarzelleLatestPurchaseList cars={data.last_purchases}/>
                                <div className="underline text-sm mt-4 text-gray-400"><a href="/dashboard/50">View all purchases ...</a></div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}