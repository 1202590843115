import React, {useEffect, useState} from "react";
import {RemoveNoneNumberCharacters, valueIfNull} from "../../Hooks/UseGenericFunctions";
import {axiosInstance} from "../../Api/AxiosConfig";
import {XCircleIcon} from "@heroicons/react/24/solid";
import {CarzelleTooltip} from "../../Components/System/Messages/CarzelleTooltip";
import {ShortMessageModal} from "../../Components/System/Messages/ShortMessageModal";
import {useDisclosure} from "@mantine/hooks";
import {NumberInput} from "@mantine/core";

export const OfferInput = React.memo(({car, fnUpdate}) => {
    const [isAdjusted, setIsAdjusted] = useState(false);
    const [adjustedOfferAmount, setAdjustedOfferAmount] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [opened, {open, close}] = useDisclosure(false);

    const setDefaultValueOfOfferAmount = () => {
        setAdjustedOfferAmount('');
        if (car.offer_amount_unconfirmed !== null) {
            setAdjustedOfferAmount(car.offer_amount_unconfirmed);
        }

        if (car.offer_amount_unconfirmed === null && car.offer_amount_confirmed > 0) {
            setAdjustedOfferAmount(car.offer_amount_confirmed);
        }
    }

    const addOffer = (hash, amount) => {
        axiosInstance
            .post("shop/add-to-basket", {carHash: hash, offerAmount: parseInt(amount, 10)})

            .then((response) => {
                if (response.data.error_message !== '') {
                    setDefaultValueOfOfferAmount();
                    setErrorMessage(response.data.error_message);
                    open();
                } else {
                    fnUpdate();
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }

    useEffect(() => {
        setIsAdjusted(false);
        setDefaultValueOfOfferAmount();
    }, [car]); // eslint-disable-line react-hooks/exhaustive-deps

    const removeFromBasket = (hash) => {
        axiosInstance
            .delete("shop/remove-from-basket/" + hash)

            .then(() => {
                fnUpdate();
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }

    // Remove the car from the basket when:
    // The unconfirmed offer is different from 0 AND the car is in the basket AND the input field is empty or 0
    // Add the car to the basket when:
    // The car doesn't have an confirmed offer AND the unconfirmed value is different from the input field AND the input field is different from 0
    // The car has a confirmed offer AND the confirmed value is different from the input field AND the unconfirmed value is different from the input field AND the input field is different from 0
    const addOrRemoveFromBasket = () => {
        let controlValueAdjustedOfferAmount =  document.getElementById('offerAmount_' + car.hash).value;
        controlValueAdjustedOfferAmount = RemoveNoneNumberCharacters(controlValueAdjustedOfferAmount);

        if (car.in_basket && valueIfNull(car.offer_amount_unconfirmed, 0) > 0 && (controlValueAdjustedOfferAmount === '' || controlValueAdjustedOfferAmount === '0')) {
            removeFromBasket(car.hash);
        } else {
            if (car.offer_amount_confirmed === null && car.offer_amount_unconfirmed !== Number(controlValueAdjustedOfferAmount) && Number(controlValueAdjustedOfferAmount) > 0) {
                addOffer(car.hash, controlValueAdjustedOfferAmount);
            }

            if (car.offer_amount_confirmed !== null && Number(car.offer_amount_unconfirmed) !== Number(controlValueAdjustedOfferAmount) && Number(controlValueAdjustedOfferAmount) > 0 && Number(car.offer_amount_confirmed) !== Number(controlValueAdjustedOfferAmount)) {
                console.log('addOffer');
                addOffer(car.hash, controlValueAdjustedOfferAmount);
            }
        }
    };

    const showDeleteButton = () => {
        return car.offer_amount_unconfirmed !== null;
    }


    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            addOrRemoveFromBasket();
        }

        if (e.key === 'Escape' && isAdjusted) {
            setDefaultValueOfOfferAmount();
        }
    };

    const borderColor = () => {
        let result = 'border-gray-200';

        if (car.offer_amount_confirmed > 0) {
            result = 'border-green-500';
        }

        if (car.offer_amount_unconfirmed !== null) {
            result = 'border-purchase-color';
        }

        return result;
    }

    const pillBackgroundColor = () => {
        let result = '';

        if (car.offer_amount_confirmed > 0) {
            result = 'bg-green-500';
        }

        if (car.offer_amount_unconfirmed > 0) {
            result = 'bg-purchase-color';
        }

        return result
    }

    const tooltip = () => {
        let result = "";
        if (car.offer_amount_unconfirmed > 0 && car.offer_amount_confirmed === null) result = "This offer is not confirmed. Please click the Basket button at the top of the page to confirm offer(s)";
        if (car.offer_amount_unconfirmed === null && car.offer_amount_confirmed === null) result = "Click to make an offer on this car and it will be added to your shopping basket. We will inform you if the seller accepts your offer";
        if (car.offer_amount_unconfirmed === null && car.offer_amount_confirmed > 0) result = "This offer is confirmed. We will get back to you";
        return result;
    }

    const infoPillText = () => {
        let result = "";
        if (car.offer_amount_unconfirmed > 0 && car.offer_amount_confirmed === null) result = "Unsubmitted offer";
        if (car.offer_amount_confirmed > 0) result = "Submitted offer";
        if (car.offer_amount_unconfirmed > 0 && car.offer_amount_confirmed > 0) result = "Raised but unsubmitted offer";
        return result;
    }

    let infoText = infoPillText();

    return (
        <div className="relative">
            <CarzelleTooltip width={400} message={tooltip()}>
                <div>
                    <div className="w-[110px]">
                        <NumberInput
                            id={'offerAmount_' + car.hash}
                            step={car.currency === "EUR" ? 100 : 1000}
                            value={adjustedOfferAmount}
                            allowDecimal={false}
                            decimalSeparator=","
                            valueIsNumericString={true}
                            placeholder="Offer"
                            rightSection={car.currency}
                            thousandSeparator="."
                            className={`placeholder-left placeholder-right text-right w-[90%] border ${borderColor()} rounded h-[30px]`}
                            onBlur={addOrRemoveFromBasket}
                            onKeyDown={handleKeyDown}
                            hideControls={true}
                            withKeyboardEvents={true}
                            size="xs"
                            styles={{
                                input: {fontSize: '12px', 'lineHeight': '12px'},
                                section: {paddingRight: '20px'},
                            }}

                        />
                    </div>
                    <div className={`absolute top-[-4px] cursor-pointer right-1 ${showDeleteButton() ? '' : 'hidden'}`} onClick={() => {
                        document.getElementById('offerAmount_' + car.hash).value = '';
                        addOrRemoveFromBasket();
                    }}>
                        <XCircleIcon className="w-4 h-4 text-red-400"/>
                    </div>

                    <div className={`absolute z-10 text-xs left-0 bottom-[-10px] px-2 py-[1px] w-[90%] text-white rounded-sm ${pillBackgroundColor()} bg-gray-500 cursor-pointer whitespace-nowrap ${infoText === '' ? 'hidden' : ''}`}>
                        {infoText}
                    </div>

                </div>
            </CarzelleTooltip>
            <ShortMessageModal message={errorMessage} opened={opened} close={close} onClose={() => {setErrorMessage('');fnUpdate()}}/>
        </div>
    )
});